import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {Helmet} from 'react-helmet-async';

function DealIframe(asinNumber){
  var productUrl = "//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ad_type=product_link&tracking_id=dfhgjsklasad-21&language=en_IN&marketplace=amazon&region=IN&placement={0}&asins={0}&show_border=false&link_opens_in_new_window=true";

  var newProductUrl = productUrl.replace("{0}", asinNumber.asinNumber);
  newProductUrl = newProductUrl.replace("{0}", asinNumber.asinNumber);
  console.log(asinNumber.asinNumber, newProductUrl);
  return (
    <iframe
    title="ok"
    sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
    style={{ width: 120, height: 240, margin: "auto" }}
    marginWidth={0}
    marginHeight={0}
    scrolling="no"
    frameBorder={0}
    src={newProductUrl}
  />
  )
}

function Product() {
  var [entity, setEntity] = useState({});
  const params = useParams();
  function loadProduct(id) {
    if (!id) {
      setEntity(undefined);
    } else {
      fetch(`https://telegramdeal.azurewebsites.net/getProduct?id=${id}`)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          // var jsonData = [];
          // jsonData = JSON.parse(json);
          if (json && json.length > 0) {
            entity = json[0];
          } else {
            entity = undefined;
          }
          setEntity(entity);
        });
    }
  }

  useEffect(() => {
    console.log("call ho rha hu");
    loadProduct(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ProductHtml(val) {
    console.log(val);
    if (!val || !val.values) {
      return (
        <>
        <Helmet>
            <title>No Product Found</title>
            <meta
              name="description"
              content="404 - No Product Found"
            />
          </Helmet>
        <section className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900">
          <div className="container px-5 py-24 mx-auto dark:text-gray-400">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20 dark:text-gray-400">
              404
              <br className="hidden sm:block dark:text-gray-400" />
              Oooops! You weren't supposed to see this
            </h1>
          </div>
        </section>
        </>
      );
    } else if (val && val.values && val.values.dealid) {
      return (
        <>
          <section className="dark:text-gray-400 dark:bg-gray-900 body-font overflow-hidden">
            <div className="container px-5 py-24 mx-auto">
              <div className="mx-auto flex flex-wrap">
                <DealIframe asinNumber={val.values.code} />
                {/* <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0"> */}
                  {/* <h2 className="text-md title-font text-gray-500 tracking-widest">
                    {moment
                      .utc(val.values.createddatetime)
                      .local()
                      .startOf("seconds")
                      .fromNow()}
                  </h2> */}
                  {/* <h2 className="text-sm title-font text-gray-500 tracking-widest">
                    AMAZON
                  </h2>
                  <h1 className="dark:text-white text-3xl title-font font-medium mb-1">
                    {val.values.title}
                  </h1>
                  <p className="leading-relaxed">{val.values.description}</p>
                  <div className="flex mt-4">
                    <a className="flex" href={val.values.url}>
                      <button className="flex ml-auto text-white bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded">
                        Buy on Amazon
                      </button>
                    </a>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return (
        <>
        <Helmet>
            <title>Product</title>
            <meta
              name="description"
              content="Product Page"
            />
          </Helmet>
          <div className="shadow p-4 mt-20 max-w-xl mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="square-full bg-blue-400 h-64 w-64" />
              <div className="flex-1 space-y-4 py-1">
                <div className="h-6 bg-blue-400 rounded w-3/4" />
                <div className="space-y-2">
                  <div className="h-6 bg-blue-400 rounded" />
                  <div className="h-6 bg-blue-400 rounded w-5/6" />
                </div>
                <div className="h-6 bg-blue-400 rounded w-3/4" />
                <div className="space-y-2">
                  <div className="h-6 bg-blue-400 rounded" />
                  <div className="h-6 bg-blue-400 rounded w-5/6" />
                </div>
                <div className="h-4 bg-blue-400 rounded w-3/4" />
                <div className="space-y-2">
                  <div className="h-10 bg-blue-400 rounded w-3/6" />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return <ProductHtml values={entity} />;
}

export default Product;
