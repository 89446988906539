//import logo from "./logo.svg";
//import "./App.css";
import moment from "moment";

function DealIframe(asinNumber){
  var productUrl = "//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ad_type=product_link&tracking_id=dfhgjsklasad-21&language=en_IN&marketplace=amazon&region=IN&placement={0}&asins={0}&show_border=false&link_opens_in_new_window=true";

  var newProductUrl = productUrl.replace("{0}", asinNumber.asinNumber);
  newProductUrl = newProductUrl.replace("{0}", asinNumber.asinNumber);
  console.log(asinNumber.asinNumber, newProductUrl);
  return (
    <iframe
    title="ok"
    sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
    style={{ width: 120, height: 240, margin: "auto" }}
    marginWidth={0}
    marginHeight={0}
    scrolling="no"
    frameBorder={0}
    src={newProductUrl}
  />
  )
}

function Card(props) {
  return (
    <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
      <div class="relative">
        <DealIframe asinNumber={props.code} />
        <span class=" absolute bg-green-100 top-0 right-0 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">{moment
            .utc(props.createddatetime)
            .local()
            .startOf("seconds")
            .fromNow()}</span>
      </div>
      {/* <a href={props.url}>
        <button class="flex mx-auto mt-10 text-center text-white bg-yellow-400 border-0 py-1 px-4 focus:outline-none hover:bg-yellow-600 rounded text-lg">
          Buy on Amazon
        </button>
      </a> */}
      {/* <div className="mt-4"> */}
        {/* <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
            {props.category}
        </h3> */}
        {/* <h2 className="text-gray-900 title-font text-lg font-medium dark:text-gray-400">
          {props.title}
        </h2> */}
        {/* <p className="mt-1">{props.price}</p> */}
        {/* <p className="mt-1">{props.description}</p> */}
      {/* </div> */}
    </div>
  );
}

export default Card;
