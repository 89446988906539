import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function History() {
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pid = searchParams.get("pid");

    if (pid) {
      const url = `https://graph.keepa.com/pricehistory.png?asin=${pid}&domain=in`;
      window.location.replace(url);
    } else {
      setError("Missing 'pid' parameter. Please provide a valid 'pid' value.");
    }
  }, [location]);

  return (
    <div>
      {error && (
        <div>
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default History;
