import { useParams } from "react-router-dom";

function RedirectTo() {
    const params = useParams();
    const url = `https://myservice2-vzh3.onrender.com/redirect?id=${params.id}`;
    window.location.replace(url);
    return null;
}

export default RedirectTo;
