import "./App.css";
import Card from "./components/Card";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
//import { LinkPreview } from '@flyerhq/react-native-link-preview'
//import { ReactTinyLink } from "react-tiny-link";
//import { LinkPreview } from '@dhaiwat10/react-link-preview';

// const Home1 = () => {
//   return <LinkPreview url='https://amazon.in/dp/B08DMG6S5R' width='400px' />;
// };
// const Home1 = () => {
//   return <LinkPreview text='This link https://amazon.in/dp/B08DMG6S5R can be extracted from the text' />
// };

function App() {
  var [entity, setEntity] = useState([]);
  //var [count, setCount] = useState(0);
  // var [count, setCount] = useState(0);
  // function activateLasers()
  // {
  //   count = count+1;
  //   setCount(count);
  //   console.log(count);
  // }
  var [count, setCount] = useState(0);

  async function fetchProducts() {
    // const scrapeAmazon = (asin) => {
    //   const url = `https://amazon.com/dp/B07TFHSDHH`
    //   console.log('Scraping URL', url)
    //   return scrapeIt(url, {
    //     price: {
    //       selector: '#price_inside_buybox',
    //       convert: p => Math.round(parseInt(p.split('$')[1])) * 100
    //     }
    //   })
    // }

    // console.log(scrapeAmazon('B07TFHSDHH'));
    //dataScrapper("https://amzn.to/B07TFHSDHH");

    fetch(
      `https://vague-tammi-changeblogger-9bfcc113.koyeb.app/getProducts?start=${count}&limit=10`
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        // var jsonData = [];
        // jsonData = JSON.parse(json);
        let copy = [];
        for (let i = 0; i < json.length; i = i + 1) {
          copy.push(Card(json[i]));
        }
        console.log(count);
        var number = count + json.length;
        setCount(number);
        setEntity([...entity, copy]);
      });
  }

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Hot Deals At Here</title>
        <meta
          name="description"
          content="Here you will get the product in best price"
        />
      </Helmet>
      <div>
        <section className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {entity}
            </div>
          </div>
          {/* <Home1 /> */}
        </section>
        <section className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900">
          <div className="container px-5 py-8 mx-auto">
            <div className="flex lg:w-3/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
              <button
                className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={fetchProducts}
              >
                Click here to load more
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default App;
